<template>
  <section class="Add-Content">
    <Loader :loading="showLoader" />
    <div class="page-header">
      <h3 class="page-title">Add Offline Order</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/order/orders" class="text-primary">Order
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Add Offline Order
          </li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="addOfflineOrder">
              <div class="mb-3">
                <label class="border-order-label">Select Customer</label>
                <div class="border p-3" style="margin-top: -9px">
                  <div class="row pt-3">
                    <div class="col-md-6">
                      <div class="form-group mb-2">
                        <label for="user_id">Select Customer</label>
                        <multiselect v-model="offlineOrder.userId" :options="useres" placeholder="Select Customer"
                          label="first_name" :class="{
                            'is-invalid':
                              submitted && $v.offlineOrder.userId.$error,
                          }"></multiselect>
                        <div v-if="submitted && !$v.offlineOrder.userId.required" class="invalid-feedback">
                          Customer is required
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group mb-2">
                        <label for="user_id">Select Status</label>
                        <multiselect v-model="offlineOrder.status_id" :options="orderStatus" placeholder="Select Status"
                          label="name" :class="{
                            'is-invalid':
                              submitted && $v.offlineOrder.status_id.$error,
                          }"></multiselect>
                        <div v-if="
                          submitted && !$v.offlineOrder.status_id.required
                        " class="invalid-feedback">
                          Status is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label class="border-order-label">Contact Details</label>
                <div class="border p-3" style="margin-top: -9px">
                  <div class="row pt-3">
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label for="user_id">Email</label>
                        <input type="email" v-model="offlineOrder.email" class="form-control" :class="{
                          'is-invalid':
                            submitted && $v.offlineOrder.email.$error,
                        }" />
                        <div v-if="submitted && $v.offlineOrder.email.$error" class="invalid-feedback">
                          <span v-if="!$v.offlineOrder.email.required">Email is required</span>
                          <span v-if="!$v.offlineOrder.email.email">Email is invalid</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label for="user_id">Mobile Number</label>
                        <input type="number" v-model="offlineOrder.mobile_number" id="firstName" name="firstName"
                          class="form-control" :class="{
                            'is-invalid':
                              submitted && $v.offlineOrder.mobile_number.$error,
                          }" />
                        <div v-if="
                          submitted && !$v.offlineOrder.mobile_number.required
                        " class="invalid-feedback">
                          Mobile Number is required
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label for="user_id">Landline Number</label>
                        <input type="number" v-model="offlineOrder.landline_number" class="form-control" />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label for="user_id">Comments</label>
                        <input type="text" v-model="offlineOrder.comments" class="form-control" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label class="border-order-label">Billing Details</label>
                <div class="border p-3" style="margin-top: -9px">
                  <div class="row pt-3">
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label for="user_id">Payment Mode</label>
                        <multiselect v-model="offlineOrder.payment_mode_id" :options="paymentMode"
                          placeholder="Select Payment Mode" label="name" :class="{
                            'is-invalid':
                              submitted &&
                              $v.offlineOrder.payment_mode_id.$error,
                          }"></multiselect>
                        <div v-if="
                          submitted &&
                          !$v.offlineOrder.payment_mode_id.required
                        " class="invalid-feedback">
                          Payment Mode is required
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label for="user_id">Name</label>
                        <input type="text" v-model="offlineOrder.billingName" class="form-control" :class="{
                          'is-invalid':
                            submitted && $v.offlineOrder.billingName.$error,
                        }" />
                        <div v-if="
                          submitted && !$v.offlineOrder.billingName.required
                        " class="invalid-feedback">
                          Billing Name is required
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label for="user_id">Company</label>
                        <input type="text" v-model="offlineOrder.billingCompany" class="form-control" :class="{
                          'is-invalid':
                            submitted &&
                            $v.offlineOrder.billingCompany.$error,
                        }" />
                        <div v-if="
                          submitted &&
                          !$v.offlineOrder.billingCompany.required
                        " class="invalid-feedback">
                          Billing Company is required
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label for="user_id">Address Line 1</label>
                        <input type="text" v-model="offlineOrder.billingAddress_1" class="form-control" :class="{
                          'is-invalid':
                            submitted &&
                            $v.offlineOrder.billingAddress_1.$error,
                        }" />
                        <div v-if="
                          submitted &&
                          !$v.offlineOrder.billingAddress_1.required
                        " class="invalid-feedback">
                          Billing Address Line 1 is required
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label for="user_id">Address Line 2</label>
                        <input type="text" v-model="offlineOrder.billingAddress_2" class="form-control" />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label for="user_id">City</label>
                        <input type="text" v-model="offlineOrder.billingCity" class="form-control" :class="{
                          'is-invalid':
                            submitted && $v.offlineOrder.billingCity.$error,
                        }" />
                        <div v-if="
                          submitted && !$v.offlineOrder.billingCity.required
                        " class="invalid-feedback">
                          Billing City is required
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label for="user_id">Country</label>
                        <multiselect v-model="offlineOrder.billingCountry" :options="country"
                          placeholder="Select Country" label="name" :class="{
                            'is-invalid':
                              submitted &&
                              $v.offlineOrder.billingCountry.$error,
                          }"></multiselect>
                        <div v-if="
                          submitted &&
                          !$v.offlineOrder.billingCountry.required
                        " class="invalid-feedback">
                          Billing Country is required
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label for="user_id">Post Code</label>
                        <input type="text" v-model="offlineOrder.post_code" class="form-control" :class="{
                          'is-invalid':
                            submitted && $v.offlineOrder.post_code.$error,
                        }" />
                        <div v-if="
                          submitted && !$v.offlineOrder.post_code.required
                        " class="invalid-feedback">
                          Post code is required
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-check mt-0">
                        <label class="form-check-label text-muted">
                          <input v-model="offlineOrder.save" type="checkbox" class="form-check-input form-group" />
                          Save Billing Details
                          <i class="input-helper"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label class="border-order-label">Shipping Details</label>
                <div class="border p-3" style="margin-top: -9px">
                  <div class="row pt-3">
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label for="user_id">Shipping Mode</label>
                        <multiselect v-model="offlineOrder.shipping_mode_id" :options="shippingMode"
                          placeholder="Select Shipping Mode" label="name" :class="{
                            'is-invalid':
                              submitted &&
                              $v.offlineOrder.shipping_mode_id.$error,
                          }"></multiselect>
                        <div v-if="
                          submitted &&
                          !$v.offlineOrder.shipping_mode_id.required
                        " class="invalid-feedback">
                          Shipping Mode is required
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label for="user_id">Name</label>
                        <input type="text" class="form-control" v-model="offlineOrder.authorized_person" />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label for="user_id">Company</label>
                        <input type="text" class="form-control" v-model="offlineOrder.shippingCompany" />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label for="user_id">Address Line 1</label>
                        <input type="text" class="form-control" v-model="offlineOrder.shippingAddress_1" />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label for="user_id">Address Line 2</label>
                        <input type="text" class="form-control" v-model="offlineOrder.shippingAddress_2" />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label for="user_id">City</label>
                        <input type="text" class="form-control" v-model="offlineOrder.shippingCity" />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label for="user_id">Country</label>
                        <multiselect v-model="offlineOrder.shippingCountry" :options="country"
                          placeholder="Select Country" label="name"></multiselect>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label for="user_id">Post Code</label>
                        <input type="text" class="form-control" v-model="offlineOrder.shippingPost_code" />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-check mt-0">
                        <label class="form-check-label text-muted">
                          <input v-model="offlineOrder.shippingSave" type="checkbox"
                            class="form-check-input form-group" />
                          Save Shipping Details
                          <i class="input-helper"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label class="border-order-label">Products</label>
                <div class="border p-3" style="margin-top: -9px">
                  <div class="pt-3">
                    <div class="row" v-for="(listOfProducts, index) in listOfProducts" :key="listOfProducts.id">
                      <div class="col-6 col-md-4 pr-2">
                        <div class="form-group mb-3">
                          <label for="user_id">Product Name</label>
                          <select class="custom-select form-control" @input="productPrice"
                            v-model="listOfProducts.productId">
                            <option value="" selected>Select Product</option>
                            <option v-for="products in products" :key="products.id" v-bind:value="products.id">
                              {{ products.name }}
                            </option>
                          </select>
                          <!-- <multiselect @input="productPrice" v-model="listOfProducts.id" :options="products"
                            placeholder="Select Product Name" label="name"></multiselect> -->

                        </div>
                      </div>
                      <div class="col-6 col-md-2 pr-2 pl-0">
                        <div class="form-group mb-3">
                          <label for="user_id">Quantity</label>
                          <input @change="productAmount" type="number" v-model="listOfProducts.productQty"
                            class="form-control" />

                        </div>
                      </div>
                      <div class="col-4 col-md-2 pr-2 pl-0">
                        <div class="form-group mb-3">
                          <label for="user_id">Price </label>
                          <input type="number" disabled v-model="offlineOrder.price" class="form-control" />
                        </div>
                      </div>
                      <div class="col-4 col-md-2 pr-2 pl-0">
                        <div class="form-group mb-3">
                          <label for="user_id">Amount </label>
                          <input type="number" disabled v-model="offlineOrder.amount" class="form-control" />
                        </div>
                      </div>
                      <div class="col-4 col-md-2 pr-0 pl-2">
                        <div class="pt-3" style="margin-top: 4px">
                          <button class="btn btn btn-add btn-primary" @click.prevent="addProduct()">
                            <i class="mdi mdi-plus"></i>
                          </button>
                          <button class="btn btn btn-add btn-dark" @click.prevent="removeProduct(index)">
                            <i class="mdi mdi-minus"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="row pt-3">
                      <div class="col-5 col-md-4 pr-1">
                        <div class="form-group mb-3">
                          <label for="user_id">Discount (add percentage)</label>
                          <input type="text" v-model="offlineOrder.discount_percentage" class="form-control" />
                        </div>
                      </div>
                      <div class="col-5 col-md-4 pl-1 pr-2">
                        <div class="form-group mb-3">
                          <label for="user_id">Shipping Charges </label>
                          <input type="text" disabled v-model="offlineOrder.shippingCharges" class="form-control" />
                        </div>
                      </div>
                      <div class="col-2 col-md-4 pl-0 pr-0">
                        <div class="pt-3" style="margin-top: 4px">
                          <button class="btn btn btn-add btn-dark" @click.prevent="shippingCharges()">
                            <i class="mdi mdi-refresh"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="row pt-3">
                      <div class="col-md-10 pr-2 col-9">
                        <div class="form-group mb-0" style="text-align: end;">
                          <label class="mb-2 w-100">Base Price :</label>
                          <label class="mb-2  w-100">Shipping Charges :</label>
                          <p class="mb-0  w-100">Sub Total :</p>
                        </div>
                      </div>
                      <div class="col-md-2 pl-0 col-3">
                        <div class="form-group mb-0">
                          <label class="mb-2  w-100">{{ offlineOrder.base_price }}</label>
                          <label class="mb-2  w-100">{{ offlineOrder.shippingCharges }}</label>
                          <p class="mb-0  w-100">{{ offlineOrder.total }}</p>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 ">
                        <label class="w-100 border-bottom"></label>
                      </div>
                      <div class="col-md-10 pr-2 col-9">
                        <div class="form-group mb-3" style="text-align: end;">
                          <label class="mb-2 w-100">Discount Percentage :</label>
                          <label class="mb-2 w-100">Discount Price :</label>
                          <p class="mb-0  w-100">Total Amount :</p>

                        </div>
                      </div>
                      <div class="col-md-2 pl-0 col-3">
                        <div class="form-group mb-3">
                          <label class="mb-2  w-100">{{ offlineOrder.discount_percentage }}%</label>
                          <label class="mb-2  w-100">{{ offlineOrder.discount_amount }}</label>
                          <p class="mb-0  w-100">{{ offlineOrder.amount }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label class="border-order-label">Submit</label>
                <div class="border p-3" style="margin-top: -9px">
                  <div class="row pt-3 pb-2">
                    <div class="col-12">
                      <button class="btn btn-primary btn-md btn-block">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
const orderApiUrl = process.env.VUE_APP_BASE_URL;
import axios from "axios";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import { catalogApi, orderApi, userApi, authApi, setingsApi } from "../../api";
import Loader from "../../components/Loader";
import Multiselect from "vue-multiselect";
export default {
  name: "new-order",
  components: {
    Loader,
    Multiselect,
  },
  data() {
    return {
      // shippingModeshipping: [],
      showLoader: false,
      orderStatus: [],
      shippingCountry: [],
      paymentMode: [],
      shippingMode: [],
      useres: [],
      country: [],
      products: [],
      listOfProducts: [
        {

          productId: "",
          productQty: "",
        },
      ],
      offlineOrder: {
        userId: "",
        status_id: "",
        email: "",
        mobile_number: "",
        payment_mode_id: "",
        billingName: "",
        billingCompany: "",
        billingAddress_1: "",
        billingCity: "",
        billingCountry: "",
        post_code: "",
        shipping_mode_id: "",
      },
      submitted: false,
    };
  },
  validations: {
    offlineOrder: {
      userId: { required },
      status_id: { required },
      email: { required, email },
      mobile_number: { required },
      payment_mode_id: { required },
      billingName: { required },
      billingCompany: { required },
      billingAddress_1: { required },
      billingCity: { required },
      billingCountry: { required },
      post_code: { required },
      shipping_mode_id: { required },
      // productId: { required },
      // productQty: { required },
    },
  },
  mounted() {
    this.status();
    this.paymentModes();
    this.shippingModes();
    this.userList();
    this.countriesList();
    this.productsList();
  },
  methods: {
    addProduct() {

      this.listOfProducts.push({
        productId: "",
        productQty: "",
      });
      console.log(this.listOfProducts);
    },
    async shippingCharges() {
      if (this.offlineOrder.shippingCountry) {
        this.offlineOrder.products = [];
        this.offlineOrder.products.push({
          id: this.offlineOrder.productId.id,
          qty: this.offlineOrder.productQty,
        });
        console.log(this.offlineOrder.shippingCountry.code, 'this.offlineOrder.shippingCountry');
        let shippingCost = {
          country_id: this.offlineOrder.shippingCountry.id,
          products: this.offlineOrder.products,
        };
        this.showLoader = true;
        const data = await orderApi.shippingCostCalculator({
          ...shippingCost,
        });
        console.log(data.data);
        if (data.data == "") {
          this.offlineOrder.shippingCharges = 0;
        } else {
          // data.data.forEach((value) => {
          this.offlineOrder.base_price = data.data.base_price;
          this.offlineOrder.shippingCharges = data.data.flat_rate;
          this.offlineOrder.total = data.data.total;
          this.offlineOrder.discount_amount = (this.offlineOrder.base_price * this.offlineOrder.discount_percentage) / 100;
          console.log(this.offlineOrder.discount_percentage);
          if (this.offlineOrder.discount_percentage == undefined) {
            this.offlineOrder.discount_percentage = 0
            this.offlineOrder.discount_amount = 0
            this.offlineOrder.amount = data.data.total;
          }
          else
            this.offlineOrder.amount = data.data.total - this.offlineOrder.discount_amount;

          // });
        }

        this.showLoader = false;
      } else
        this.$swal({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 3000,
          type: "error",
          text: "Please add shipping address ",
        });
    },
    // discountAmount() {
    //   let data = [
    //     { price: this.offlineOrder.amount },


    //   ];
    //   let sumsArray = {};
    //   data.forEach((item) => {
    //     let sums = sumsArray[item.price];
    //     if (sums) {
    //       sums.price += item.price;
    //     } else {
    //       sumsArray[item.price] = {
    //         price: item.price,
    //       };
    //     }
    //   });
    // },
    async productPrice() {
      console.log(this.listOfProducts);
      // const { data } = await catalogApi.getProductById(

      // );
      // let products = data.data.data;
      // products.forEach((value) => {
      //   if (this.offlineOrder.productQty == "") {
      //     this.offlineOrder.price = value.price;
      //     this.offlineOrder.amount = value.price * 0;
      //   } else {
      //     this.offlineOrder.price = value.price;
      //     this.offlineOrder.amount = value.price * this.offlineOrder.productQty;
      //   }
      // });
      // this.showLoader = false;
    },
    async productAmount() {
      let amount = this.offlineOrder.price * this.offlineOrder.productQty;
      this.showLoader = true;
      this.offlineOrder.amount = amount;
      this.showLoader = false;
    },
    removeProduct(index) {
      this.listOfProducts.splice(index, 1);
    },
    async status() {
      this.showLoader = true;
      const { data } = await orderApi.status();
      this.orderStatus = data.data.data;
      this.showLoader = false;
    },
    async paymentModes() {
      this.showLoader = true;
      const { data } = await setingsApi.paymentModes();
      this.paymentMode = data.data.data;
      this.showLoader = false;
    },
    async shippingModes() {
      this.showLoader = true;
      const { data } = await setingsApi.shipping();
      this.shippingMode = data.data.data;
      this.showLoader = false;
    },
    async userList() {
      this.showLoader = true;
      const { data } = await userApi.userList();
      this.useres = data.data.data;
      this.showLoader = false;
    },
    async countriesList() {
      this.showLoader = true;
      const { data } = await authApi.countries();
      this.country = data.data.data;
      this.showLoader = false;
    },
    async productsList() {
      this.showLoader = true;
      const { data } = await catalogApi.productsList();
      this.products = data.data.data;
      this.showLoader = false;
    },
    async addOfflineOrder() {
      this.submitted = true;

      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 3000,
          type: "error",
          text: "Please fill required fields ",
        });
        return;
      }


      // this.offlineOrder.shipping = [];
      this.offlineOrder.billing = [];



      if (this.offlineOrder.userId) {
        this.offlineOrder.userId = this.offlineOrder.userId.id;
      }

      if (this.offlineOrder.status_id) {
        this.offlineOrder.status_id = this.offlineOrder.status_id.id;
      }

      this.offlineOrder.product_id = [];
      this.offlineOrder.product_quantity = [];
      if (this.listOfProducts) {
        // console.log(this.listOfProducts, 'ddd');
        this.listOfProducts.forEach((value) => {
          var products = value.productId;
          var productsQty = value.productQty;

          this.offlineOrder.product_id.push(products);
          this.offlineOrder.product_quantity.push(productsQty);
        })

      }
      if (this.offlineOrder.payment_mode_id) {
        this.offlineOrder.payment_mode_id =
          this.offlineOrder.payment_mode_id.id;
      }

      if (this.offlineOrder.save === true) {
        this.offlineOrder.save = 1;
      } else {
        this.offlineOrder.save = 0;
      }

      this.offlineOrder.billing = {
        name: this.offlineOrder.billingName,
        company: this.offlineOrder.billingCompany,
        address_1: this.offlineOrder.billingAddress_1,
        address_2: this.offlineOrder.billingAddress_2,
        city: this.offlineOrder.billingCity,
        country_id: this.offlineOrder.billingCountry.id,
        post_code: this.offlineOrder.post_code,
        save: this.offlineOrder.save,
      };

      if (this.offlineOrder.shipping_mode_id) {
        this.offlineOrder.shipping_mode_id =
          this.offlineOrder.shipping_mode_id.id;
      }
      if (this.offlineOrder.shippingCountry) {
        this.offlineOrder.shippingCountry =
          this.offlineOrder.shippingCountry.id;
      }

      if (this.offlineOrder.shippingSave === true) {
        this.offlineOrder.shippingSave = 1;
      } else {
        this.offlineOrder.shippingSave = 0;
      }

      // this.offlineOrder.shipping.push({
      //   authorized_person: this.offlineOrder.authorized_person,
      //   company: this.offlineOrder.shippingCompany,
      //   address_1: this.offlineOrder.shippingAddress_1,
      //   address_2: this.offlineOrder.shippingAddress_2,
      //   city: this.offlineOrder.shippingCity,
      //   country_id: this.offlineOrder.shippingCountry,
      //   post_code: this.offlineOrder.shippingPost_code,
      //   save: this.offlineOrder.shippingSave,
      // });

      for (var key in this.offlineOrder) {
        if (this.offlineOrder.hasOwnProperty(key)) {
          var val = this.offlineOrder[key];
          if (val === null) {
            delete this.offlineOrder[key];
          }
        }
        delete this.offlineOrder["billingName"];
        delete this.offlineOrder["billingCompany"];
        delete this.offlineOrder["billingAddress_1"];
        delete this.offlineOrder["billingAddress_2"];
        delete this.offlineOrder["billingCity"];
        delete this.offlineOrder["billingCountry"];
        delete this.offlineOrder["post_code"];
        delete this.offlineOrder["save"];

        delete this.offlineOrder["authorized_person"];
        delete this.offlineOrder["shippingCompany"];
        delete this.offlineOrder["shippingAddress_1"];
        delete this.offlineOrder["shippingAddress_2"];
        delete this.offlineOrder["shippingCity"];
        delete this.offlineOrder["shippingCountry"];
        delete this.offlineOrder["shippingPost_code"];
        delete this.offlineOrder["shippingSave"];

        delete this.offlineOrder["productId"];
        delete this.offlineOrder["productQty"];
        delete this.offlineOrder["price"];
        delete this.offlineOrder["total"];
        delete this.offlineOrder["base_price"];
        delete this.offlineOrder["discount_percentage"];
        delete this.offlineOrder["amount"];

      }

      this.showLoader = true;
      this.offlineOrder.user_id = this.offlineOrder.userId
      axios
        .post(`${orderApiUrl}/api/orders/order/create`, this.offlineOrder, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((response) => {
          this.showLoader = false;
          if (response.data.code == 200) {
            this.$router.push("/order/orders");
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              type: "success",
              title: " ",
              text: "Successfully Uploaded",
            });
          } else {
            this.$swal({
              type: "error",
              title: "Oops...",
              text: "The given data was invalid",
            });
          }
          // console.log(response, 'ddd');
        })

      // const response = orderApi.createOrder(this.offlineOrder).then((response) => {
      // // const data = await orderApi.createOrder({
      // //   ...this.offlineOrder,
      // // });
      // console.log(response,'ddd');
      // this.showLoader = false;})}
      //  if (response.data.code == 200) {
      //       this.$router.push("/order/orders");
      //       if (data.status == 200) {
      //         this.$swal({
      //           toast: true,
      //           position: "top-end",
      //           showConfirmButton: false,
      //           timer: 3000,
      //           type: "success",
      //           title: " ",
      //           text: data.messages,
      //         });
      //       } else {
      //         this.$swal({
      //           type: "error",
      //           title: "Oops...",
      //           text: data.messages,
      //         });
      //       }
      //       console.log(this.offlineOrder);
      //       // alert("SUCCESS!! :-)\n\n" + JSON.stringify(this.offlineOrder));
      //     },
      // },
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css">
</style>
<style scoped>
.border-order-label {
  background: #fff;
  margin-left: 22px;
  padding: 0px 9px;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 15px;
}

.form-group label {
  font-size: 12px;
}

.border {
  border: 1px solid #2125299e !important;
}
</style>